<template>
  <div>
    <div class="form--group row" :class="{ 'has-error': validation.hasError('province') }">
      <div class="col-12 col-lg-3">
        <label for="province-id-field">
          <span>{{ $t('general.province') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="col-12 col-lg-6">
        <multiselect
          id="province-id-field"
          name="province"
          v-model="province"
          :options="provinces"
          track-by="id"
          label="name"
          :searchable="true"
          :close-on-select="true"
          :class="validation.hasError('province') ? 'is-invalid' : ''"
          :show-labels="true"
          :placeholder="$t('general.province')"
        >
        </multiselect>
        <span class="val-error" v-if="validation.hasError('province')">{{
          validation.firstError('province')
        }}</span>
      </div>
    </div>
    <div
      v-show="openCity"
      class="form--group row"
      :class="{ 'has-error': validation.hasError('city') }"
    >
      <div class="col-12 col-lg-3">
        <label for="city-id-field">
          <span>{{ $t('general.city') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="col-12 col-lg-6">
        <multiselect
          id="city-id-field"
          name="city"
          v-model="city"
          :options="cities"
          track-by="id"
          label="name"
          :searchable="true"
          :close-on-select="true"
          :class="validation.hasError('city') ? 'is-invalid' : ''"
          :show-labels="true"
          :placeholder="$t('general.city')"
        >
        </multiselect>
        <span class="val-error" v-if="validation.hasError('city')">{{
          validation.firstError('city')
        }}</span>
      </div>
    </div>
    <div v-show="openDistrict" class="my-4">
      <div class="form--group row">
        <div class="col-12 col-lg-3">
          <label for="district-id-field">
            <span>{{ $t('general.district') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="col-12 col-lg-6">
          <multiselect
            name="district"
            id="district-id-field"
            v-model="district"
            :options="districts"
            track-by="id"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            :placeholder="$t('general.district')"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('district')">{{
            validation.firstError('district')
          }}</span>
        </div>
      </div>
      <div class="form--group row">
        <div class="col-12 col-lg-3">
          <label for="village-id-field">
            <span>{{ $t('general.village') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="col-12 col-lg-6">
          <multiselect
            id="village-id-field"
            name="village"
            v-model="village"
            :options="villages"
            track-by="id"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            :placeholder="$t('general.village')"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('village')">{{
            validation.firstError('village')
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import { Validator } from 'simple-vue-validator';
import { mapState } from 'vuex';

export default {
  name: 'regional-section',
  props: ['isActive'],
  components: { Multiselect },
  computed: {
    ...mapState({
      provinces: (state) => state.project.form.regional.provinces,
      cities: (state) => state.project.form.regional.cities,
      districts: (state) => state.project.form.regional.districts,
      villages: (state) => state.project.form.regional.villages,
      openDistrict: (state) => state.project.form.regional.openDistrict,
      openCity: (state) => state.project.form.regional.openCity,
    }),
    province: {
      get() {
        return this.$store.state.project.form.regional.province;
      },
      set(value) {
        this.$store.dispatch('project/form/regional/changeProvince', value);
        this.$store.dispatch('project/form/regional/getCities', value);
      },
    },
    city: {
      get() {
        return this.$store.state.project.form.regional.city;
      },
      set(value) {
        this.$store.dispatch('project/form/regional/changeCity', value);
        this.$store.dispatch('project/form/regional/getDistricts', value);
      },
    },
    district: {
      get() {
        return this.$store.state.project.form.regional.district;
      },
      set(value) {
        this.$store.dispatch('project/form/regional/changeDistrict', value);
        this.$store.dispatch('project/form/regional/getVillages', value);
      },
    },
    village: {
      get() {
        return this.$store.state.project.form.regional.village;
      },
      set(value) {
        this.$store.dispatch('project/form/regional/changeVillage', value);
      },
    },
  },
  methods: {
    validate() {
      // console.log('validate province')
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
  },
  validators: {
    province(value) {
      if (this.isActive) {
        return Validator.value(value).required(this.$i18n.t('errors.addProject.province.required'));
      } else {
        return Validator.value(true);
      }
    },
    city(value) {
      if (this.isActive) {
        return Validator.value(value).required(this.$i18n.t('errors.addProject.city.required'));
      } else {
        return Validator.value(true);
      }
    },
    district(value) {
      if (this.isActive) {
        return Validator.value(value).required(this.$i18n.t('errors.addProject.district.required'));
      } else {
        return Validator.value(true);
      }
    },
    village(value) {
      if (this.isActive) {
        return Validator.value(value).required(this.$i18n.t('errors.addProject.village.required'));
      } else {
        return Validator.value(true);
      }
    },
  },
};
</script>

<style scoped></style>
